.user-button {
  font-size: 12px;
  display: block;
  width: 250px; /* fixed width for uniformity */
  margin: 12px auto;
  padding: 10px 20px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  text-decoration: none; /* removes underline by default */
  font-weight: 500;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  color: inherit;
}

.user-button:hover {
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  text-decoration: none; /* ensures underline doesn't reappear on hover */
}
