html {
  font-size: 62.5%;
}

button {
    font-family: inherit;
}
/*
header {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
*/
/* Main Page Grid */
.container {
  display: grid;
  /* grid-template-columns: 33% 33% 33%; */

  grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));

  /* Adjust columns based on available space */

  margin: auto;
  width: 100%;
  max-width: 68rem;
  gap: 0.2rem; /*Controls gap between columns and height*/
}
@media (max-width: 480px) {
  .container {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* Force 3 columns on small screens */
  }
}
a {
  /* Without this, the anchor's height
    gets a few pixels too big.
  */
  line-height: 0.1rem;
}

.intl-links {
  max-height: 30vh;
  margin-bottom: 1.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.intl-links p {
  font-family: Karla, sans-serif;
  font-size: 1.5rem;
  line-height: 1.6em;
  overflow-wrap: break-word;
  overflow-x: hidden;
}

.intl-links a {
  color: #dd5f5f;
  font-weight: bold;
}

.intl-links a:hover {
  color: black;
  text-decoration: none;
}

img {
  width: 100%;
  /* cursor: pointer; */
}

/* Table Used for Header */
table {
  margin: auto;
  margin-top: 1.43rem;
}

.ig-handle {
  margin-left: 10px; /* Push the text further right */
  font-size: 1.2rem;
  font-weight: 700;
}

.avatar {
  border-radius: 50%;
  width: 45px;
  margin-top: -2.5px;
}


.top-align {
  vertical-align: top;
}

.modal-verbiage {
  margin-bottom: 0;
  /* line-height: 0.5rem; */
  text-align: center;
  font-size: 1.4rem;
}

.modal-verbiage h3 {
  margin-bottom: 3rem;
}

.modal-verbiage p {
  text-align: left;
  font-family: "Times New Roman", Times, serif;
}

.paid-links {
  font-size: 1.3rem;
  font-family: "Times New Roman", Times, serif;
  text-align: right;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  margin-top: 0;
}

.paid-links p {
  text-align: right;
}

.call-to-action {
  font-size: 1.23rem;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 0.2rem;
  margin-block-end: 2px !important;
}

.homepage-disclosure {
  font-size: 1.2rem;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
  margin-block-start: 0px !important;
  text-align: center;
  color: black;
  opacity: 0.5;
}

/* TabGroup.css */
.tab {
  font-size: 1.2rem;
  padding: 2px 60px;
  margin-bottom: 4px;
  cursor: pointer;
  opacity: 0.35;
  background: white;
  color: black;
  border: 1px solid transparent;
  outline: 0;
  flex: 1;
  transition: border-color 0.2s ease, opacity 0.2s ease;
}

.tab.active {

  border-color: black;
  font-weight: 500;
  opacity: 1;
}

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96%;
  margin: 0 auto;
  position: relative;
}



/*This is the gray background that fills the screen when a modal is open*/
.modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0.5em;
  box-shadow: 0 1rem 2rem rgba(black, 0.2);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed; /* Change to fixed to ensure it stays centered even on scroll */
  z-index: 1000;
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  background: #ffffff;
  border-radius: 0.5em;
  width: 80%;
  height: 80%;
  max-width: 300px;
  max-height: 600px;
  padding: 2rem 2rem;
  /* text-align: left; */
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
  margin-bottom: 2rem;
  gap: 0.5rem;
  /* align-items: flex-start; */
  /* Align items with space between */
}

.modal-primary-img {
  /* height: 30%; */
  width: 75%;
  max-width: 225px;
  /* max-width: 40%; */
  height: auto;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  /* flex-grow: 0.5; */
}

@media (max-height: 740px) {
  .modal-primary-img {
    width: 75%;
  }

  .modal-content {
    height: 90%;
    margin-top: 1rem;
  }
}

/* Grid for Image Links */
.image-links-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);

  gap: 0.2rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
  aspect-ratio: 1/1;
  max-width: 300px;
}

.image-links-grid a {
  display: block;
  width: 100%;
}

.image-links-grid img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.close-button-container {
  margin: 0 auto;
  display: block;
  color: gray;
  background-color: white;
  border: none;
  font-size: 2.6rem;
  width: 10%;
  cursor: pointer;
  /* transition: background-color 0.3s ease; */
  position: absolute;
  top: 0;
  right: 0;
  /* transform: translateX(-50%); */
}

.outer-div {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
}

.post-image {
  display: block;
  cursor: pointer;
}

.linkdiv {
  cursor: pointer;
  width: 100%;
  line-height: 0.1rem;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.cookie-settings {
  text-align: center; /* Center the link */
  margin: 20px 0; /* Adds space above and below */
}

.cookie-settings a {
  font-size: 1.2rem; /* Increases font size */
  font-weight: bold; /* Makes it stand out */
  padding: 10px 15px; /* Adds some padding for better visibility */
  text-decoration: none; /* Removes underline */
  color: #007bff; /* Optional: Make it stand out */
  display: inline-block; /* Keeps it from stretching full width */
}

.cookie-settings a:hover {
  text-decoration: underline; /* Adds underline on hover */
}
